const Marketplace = () => {
  return (
    <>
      <div className="hro">
        <h2 className="italic text-left text-xl mt-4 font-bold text-gray">
          MARKETPLACE
        </h2>
        <p className="text-sm mt-2">
          Para os produtos adquiridos nos diversos marketplaces com os quais a
          Decathlon firmou contratos, qualquer troca ou devolução seguirá a
          política do respectivo local de compra. A loja física ou o Atendimento
          ao Cliente Decathlon não poderá, em qualquer hipótese, proceder a
          troca ou devolução destes produtos.
        </p>
        <p className="text-sm mt-2">
          Adquirindo produtos no site ou aplicativo Decathlon que não são
          vendidos e entregues pela Decathlon não haverá, em nenhuma hipótese, a
          troca da mercadoria. Se desejar, poderá solicitar a devolução do
          produto no prazo de até 7 (sete) dias corridos a contar da data do
          recebimento. Para fazer sua solicitação entre em contato com nossa
          Central de Atendimento. No prazo de até 48h úteis será enviado, para o
          e-mail informado na hora da compra, o código para envio da mercadoria.
          O produto deve ser postado no Correios devidamente embalado, com sua
          Nota Fiscal e sem indícios de uso. Após análise e confirmação de
          recebimento pelo parceiro, o estorno será iniciado e será feito de
          acordo com a forma de pagamento, que pode levar até 30 dias para a
          conclusão.
        </p>
        <p className="text-sm mt-2">
          Ao adquirir produtos marca Decathlon comprados em outros sites que não
          o próprio, a troca e/ou devolução deve ser tratada diretamente na
          plataforma na qual foi feita a aquisição. Nossa Central de Atendimento
          e lojas físicas não prosseguirão, em nenhuma hipótese, com a troca
          e/ou devolução desse material.
        </p>
      </div>
    </>
  )
}

export default Marketplace
