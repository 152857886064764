import Button from 'src/components/ui/Button'

const SiteApp = () => {
  return (
    <>
      <div className="hro">
        <h2 className="text-gray italic text-lg font-bold text-left">
          SITE E APP
        </h2>
        <p className="text-left text-sm mt-4 mb-4">
          Para troca ou devolução de produtos comprados no site e/ou aplicativo
          clique no botão abaixo ou vá direto em uma de nossas lojas físicas.
        </p>
        <Button className="bg-blue justify-center text-white text-xs my-0 m-auto flex items-center h-[30px] max-w-[234px] w-full hover:bg-darkBlue transition-all rounded-sm">
          <a
            className="consult-button text-xs font-bold uppercase"
            href="https://decathlon.troquefacil.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            Iniciar o processo de Troca
          </a>
        </Button>
        <p className="text-sm text-center !text-shadeOfGray mt-2">
          Faça sua solicitação de trocas e/ou devoluções.
        </p>
        <p className="text-sm mt-4 text-gray">
          <strong>Passo a passo para solicitar troca e/ou devolução</strong>
        </p>
        <ul className="exchanges-return_list text-left text-sm">
          <li className="text-sm mt-4">
            Acesse o link do{' '}
            <a href="https://decathlon.troquefacil.com.br/">Troque Fácil</a>;
          </li>
          <li className="text-sm">Clique em Começar;</li>
          <li className="text-sm">
            Digite o número do pedido (ex: 1025851359142-01) e o mesmo endereço
            de e-mail utilizado para realizar a compra. Clique em Buscar pedido
          </li>
          <li className="text-sm">
            Escolha os itens do pedido, optando por Troca e/ou Devolução;
          </li>
          <li className="text-sm">
            Informe o motivo pelo qual está solicitando a troca e/ou devolução.
            Clique em Continuar; (Necessário anexar uma foto do produto em caso
            de defeito ou recebimento divergente)
          </li>
          <li className="text-sm">
            Clique no método de devolução disponível que melhor lhe atenda;
          </li>
          <li className="text-sm">Confirme os dados e finalize.</li>
        </ul>
        <p className="text-sm mt-2">
          Em até 1 (um) dia útil receberá um e-mail com o código de postagem via
          Correios ou o agendamento de retirada no caso de produtos volumosos ou
          entrega divergente. Neste código de postagem não está incluso a
          embalagem para envio e fica ao encargo do consumidor providenciar uma
          embalagem se não possuir mais a que foi encaminhada. O produto
          chegando até nós em perfeitas condições, você receberá um vale troca
          para ser utilizado em nosso site ou em loja física ou prosseguiremos
          com sua restituição assim como solicitado.
        </p>
        <p className="text-sm mt-2">
          É possível trocar e/ou devolver em loja física compras realizadas pelo
          site e aplicativo. Para isso basta comparecer ao Atendimento ao
          Cliente de qualquer unidade Decathlon no Brasil com o produto e a nota
          fiscal.
        </p>
        <p className="text-sm mt-2">
          Chegando em nosso Centro de Distribuição, o produto devolvido será
          submetido à análise. Eventual troca ou devolução somente será liberada
          após aprovação que poderá levar até 7 dias úteis. Caso seja
          identificada alguma violação do produto ou em discordância com as
          condições aqui estabelecidas o mesmo será devolvido ao remetente no
          estado em que foi recebido sem qualquer comunicação prévia.
        </p>
        <p className="text-sm mt-2">
          Frete para pedidos com mais de um ítem: Caso o produto sujeito a troca
          ou devolução pertença a um pedido com outros itens, o valor do frete
          para o cálculo do vale troca ou reembolso será calculado de forma
          proporcional àquele pago pelo cliente. Esse valor será calculado
          proporcionalmente de acordo com o valor da peça pelo o valor total da
          compra, não considerando o número de itens adquiridos.
        </p>
      </div>
    </>
  )
}

export default SiteApp
