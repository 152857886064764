/* eslint-disable react/no-unescaped-entities */
import SidebarService from 'src/components/ui/SidebarService/SidebarService'
import { Image } from 'src/components/ui/Image'

import Marketplace from './Marketplace'
import SiteApp from './SiteApp'

import '../styles.scss'

const TrocasDevolucoes = () => {
  return (
    <>
      <section className="section-services exchanges-return_container w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar TrocasDevolucoes-> */}
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- TrocasDevolucoes Products-> */}
              <div className="services-content">
                <div className="section__top align-center">
                  <h1 className="text-blue italic text-2xl font-bold text-center">
                    TROCAS E DEVOLUÇÕES
                  </h1>

                  <p className="text-left text-gray text-sm mt-4">
                    {' '}
                    <strong>Troca: </strong>substituição do produto por
                    liberalidade do cliente. Tal substituição gera um crédito
                    (vale troca) o qual poderá ser utilizado para a aquisição de
                    um novo produto. Somente poderão ocorrer, conforme as normas
                    abaixo, considerando as seguintes premissas:
                  </p>
                  <ul className="exchanges-return_list text-left text-sm">
                    <li className="text-sm mt-4">
                      O prazo de troca padrão é de 30 (trinta) dias corridos
                      contados a partir da data da compra realizada em loja
                      física e da entrega/retirada em loja se comprada em meios
                      digitais. Clientes do Clube Decathlon poderão fazer suas
                      trocas em até 45 (quarenta e cinco) dias corridos;
                    </li>
                    <li className="text-sm">
                      É obrigatório a apresentação de cupom fiscal e/ou cupom
                      presente;
                    </li>
                    <li className="text-sm">
                      O produto, etiquetas e embalagens originais devem estar
                      nas mesmas condições de venda;
                    </li>
                    <li className="text-sm">
                      O produto não pode apresentar sinais de utilização;
                    </li>
                    <li className="text-sm">
                      Não haverá, em nenhuma hipótese, troca de produto
                      adquirido em loja física via e-commerce.
                    </li>
                  </ul>
                  <p className="text-left text-sm mt-2">
                    <strong>Devolução:</strong> pode ocorrer em duas hipóteses:
                    (i) mera liberalidade do cliente em até 7 (sete) dias
                    corridos da entrega de produto adquirido fora da loja
                    física; (ii) defeito identificado no produto após esse
                    prazo. Deverão ser consideradas as seguintes premissas:
                  </p>
                  <ul className="exchanges-return_list text-left text-sm">
                    <li className="text-sm mt-4">
                      A devolução somente poderá ocorrer para produtos
                      adquiridos via e-commerce;
                    </li>
                    <li className="text-sm">
                      O prazo para devolução será de 7 (sete) dias corridos
                      contados da entrega ou retirada na loja e/ou caso o mesmo
                      apresente defeito dentro do prazo de garantia;
                    </li>
                    <li className="text-sm">
                      É obrigatória a apresentação de cupom fiscal;
                    </li>
                    <li className="text-sm">
                      O produto, etiquetas e embalagens originais devem estar
                      nas mesmas condições de venda, não podendo apresentar
                      sinais de utilização. Exceto para os casos de defeito;
                    </li>
                  </ul>
                  <p className="text-sm text-left mt-4">
                    Todos os produtos que são produzidos pela Decathlon possuem
                    garantia mínima de 02 anos. No caso de outras marcas, o
                    prazo é de 90 dias de acordo com o Código de Defesa do
                    Consumidor.
                  </p>
                </div>
                <SiteApp />
                <div className="hro">
                  <p className="text-black text-left text-sm">
                    Sobre a postagem e coleta
                  </p>

                  <ol className="text-left text-sm">
                    <li className="text-sm mt-4 text-gray pl-10">
                      <p className="my-4">
                        01. <strong>Produtos Pequenos e/ou Leves</strong>
                      </p>
                      <p className="text-sm">
                        Com até 30kg e 1,05 altura x 1,05 largura, o produto
                        deverá ser devolvido para o nosso Centro de Distribuição
                        por meio de postagem com transporte pago pela Decathlon
                        em sua na primeira troca e/ou devolução. O código para
                        postagem será enviado via e-mail assim que a solicitação
                        de troca ou devolução for aprovada. Poderá fazer o envio
                        em qualquer agência credenciada dos Correios, sem custo
                        adicional.
                      </p>
                    </li>

                    <li className="text-sm text-gray pl-10">
                      <p className="my-4">
                        02. <strong>Produtos Grandes e/ou Pesados</strong>
                      </p>
                      <p className="text-sm">
                        São aqueles que possuem mais de 30kg e/ou com dimensões
                        acima de 160cm³ (comprimento x altura x largura). Para
                        esses casos prosseguirmos com a coleta no endereço de
                        entrega. Assim que autorizado a troca ou devolução,
                        receberá um email com a informação da visita da
                        transportadora. Para que a solicitação seja atendida,
                        será necessário que os produtos sejam enviados completos
                        com todos os itens como acessórios e manuais,
                        acompanhado da Nota Fiscal/DANFE e devidamente embalado
                        e protegido.
                      </p>
                    </li>
                    <Image
                      className="pl-10"
                      src="https://decathlonproqa.vtexassets.com/arquivos/como-medir.png"
                      width={256}
                      height={179}
                      alt="como-medir"
                      loading="lazy"
                    />
                  </ol>
                  <p className="text-sm mt-2">
                    As coletas são feitas de segunda-feira a sexta-feira, das 8h
                    às 18h, ou de acordo com a disponibilidade do parceiro
                    transportador, que eventualmente pode ocorrer aos sábados.
                    Serão feitas até 2 (duas) tentativas de coleta no prazo de
                    até 2 (dois) dias úteis entre elas. Caso não consiga retirar
                    o produto em nenhuma delas, a solicitação será cancelada.
                    Para prédios, quando necessária a coleta no apartamento do
                    cliente, somente serão realizadas desde que não causem
                    riscos à segurança do transportador e ao produto.
                  </p>
                  <p className="text-sm mt-2">
                    São considerados como "riscos": a. levar o produto em
                    escadas caracóis ou escadas estreitas; b. levar o produto em
                    escadas acima do 3º lance, quando não há elevador no prédio
                    ou o elevador não suporta as dimensões do produto.
                  </p>
                  <p className="text-sm mt-2">
                    Caso o comprador se recuse a devolver o produto, a recusa
                    será interpretada como renúncia ao pedido de cancelamento.
                    Assim, os procedimentos de coleta serão cancelados e nenhum
                    valor será restituído ao comprador.
                  </p>
                </div>
                <div className="hro">
                  <p className="text-gray text-left text-sm mt-4">
                    <strong>Produto divergente</strong>
                  </p>
                  <p className="text-sm mt-2">
                    Caso você tenha recebido seu produto com qualquer
                    divergência ou produto em desacordo com adquirido poderá
                    solicitar a troca via Troque Fácil selecionando a opção "Não
                    é o produto escolhido". Dessa forma, poderá dar sequência em
                    sua solicitação e escolher entre comparecer a uma agência
                    dos Correios para fazer a postagem do produto recebido ou
                    que façamos a coleta no endereço de entrega.
                  </p>
                </div>
                <div className="hro">
                  <h2 className="italic text-left text-xl mt-4 font-bold text-gray">
                    LOJA FÍSICA
                  </h2>
                  <p className="text-sm mt-2">
                    Produtos adquiridos em qualquer loja Decathlon no Brasil
                    poderão ser trocados em qualquer uma das unidades no país
                    independente de ser a mesma da compra inicial ou não. Para
                    isso, basta comparecer ao Atendimento ao Cliente com o
                    produto e a nota fiscal ou cupom presente.
                  </p>
                  <p className="text-sm mt-2">
                    Produtos comprados via e-commerce na modalidade Retire na
                    Loja ou Express estão excluídos desta modalidade.
                  </p>
                </div>
                <Marketplace />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TrocasDevolucoes
